import '../style/privacy.scoped.scss'
import React, { useState, useEffect } from "react"
import Seo from '../components/seo'
import Layout from "../components/layout"
import Icon from '../components/iconfontCpt'

const titles = ['声明和承诺', '定义', '许可权利和限制', '知识产权及保密', '违约责任', '信息收集', '免责声明;责任限制', '无技术支持', '审计;终止', '双方的关系', '完整协议;准据法', '通知']
let isClick = false
const Privacy = ({ pageContext }) => {
  const pageLang = pageContext.lang

  const [activeAnchor, setActiveAnchor] = useState(0)
  const handleAnchorClick = (idx) => {
    setActiveAnchor(idx)
    const domNode = document.getElementById(idx)
    const scrollTop = domNode.offsetTop - 100
    try {
      window.scroll({
        behavior: 'smooth',
        top: scrollTop,
      })
    } catch(err) {
      if (err instanceof TypeError) {
        window.scroll(0, scrollTop)
      } else {
        throw err
      }
    }
    isClick = true
    setTimeout(() => {
      isClick = false
    }, 500)
  }

  useEffect(() => {
    window.addEventListener("scroll", scrollListenerFunc)
    return () => {
      // 移除事件
      window.removeEventListener("scroll", scrollListenerFunc)
    }
  }, []);

  function scrollListenerFunc () {
    const scrollTopVal = document.documentElement.scrollTop || document.body.scrollTop

    let activeAnchorIdx = 0
    titles.forEach((title, idx) => {
      const domNodeTop = document.getElementById(idx).offsetTop
      if (scrollTopVal > domNodeTop) {
        activeAnchorIdx = idx + 1
        return
      }
    })
    if (!isClick) setActiveAnchor(activeAnchorIdx > titles.length ? titles.length - 1 : activeAnchorIdx)
  }


  return (
    <Layout pageLang={ pageLang } showHeader={true} showFooter={true} useTransparentTheme={false}>
      <Seo title="MogDB: Privacy"/>
      <div className="privacy-container">
        <div className="left-box">
          <h1>MogDB 企业版数据库管理系统 软件许可协议</h1>
          <div className="effective-date">生效日期：2022年6月30日</div>
          <p className="description"><span className="strong">云和恩墨</span>同意授权您访问与本许可协议（“协议”）【MogDB 企业版数据库管理系统[简称：MogDB] 】软件（以下简称“MogDB ”），前提是您接受本协议约束您对该软件的使用。选择<span className="strong">“安装或使用程序”</span>按钮或框（或等效项）或安装或使用程序，即表示您作为您的公司或组织的授权代表或作为个人接受本协议，以遵守适用于您希望下载和访问的软件的许可条款。如果您不同意受本协议的约束，请不要选择“安装或使用程序”按钮或框（或同等按钮），也不要下载或访问该软件。</p>
          <h2 id="0">1. 声明和承诺</h2>
          <p>如云和恩墨需要对本协议进行变更或修改的，云和恩墨会通过云和恩墨网站【<a className="link" href="http://enmotech.com" target="_blank">enmotech.com</a>】网站公告的方式提前予以公布；若您在本协议内容公告变更后继续使用【MogDB】软件的，表示您已充分阅读、理解并接受变更后的协议内容，也将遵循变更后的协议内容。</p>
          <div className="blank-line"></div>
          <h2 id="1">2. 定义</h2>
          <ul className="list">
            <li>“云和恩墨”是指云和恩墨（北京）信息技术有限公司。</li>
            <li>“MogDB 企业版数据库管理系统[简称：MogDB] 软件”是指MogDB 企业版数据库管理系统[简称：MogDB] 3.0及其相关产品、MogDB 企业版数据库管理系统[简称：MogDB] 5.0及其相关产品、云和恩墨后续发布的MogDB企业版数据库管理系统5.0以上版本，但云和恩墨明确声明不包含在其中的除外。</li>
            <li>“程序”是指云和恩墨根据本协议提供的【MogDB】软件以及云和恩墨提供的任何更新、错误更正和/或程序文档。</li>
            <li>“您”是指（a）访问程序的公司或组织（每个“实体”）;或（b）访问程序的个人。</li>
            <li>“承包商”是指您的代理商和承包商（包括但不限于外包商）。</li>
            <li>“程序文档”是指程序用户手册和程序安装手册（如果有）。如果可用，程序文档可以与程序一起提供和/或可以从【<a className="link" href="http://docs.mogdb.io" target="_blank">docs.mogdb.io</a>】访问。</li>
            <li>“单独条款”是指在程序文档、自述文件或通知文件中指定并适用于单独许可的第三方技术的单独许可条款。</li>
            <li>“知识产权”是指【MogDB】软件相关的一切版权、商标权、专利权、商业秘密等知识产权，以及与该软件相关的所有信息内容，包括但不限于：文字表述及其组合、图标、图饰、图表、色彩、界面设计、版面框架、有关数据、印刷材料或电子文档。</li>
          </ul>
          <h2 id="2">3. 许可权利和限制</h2>
          <p>云和恩墨授予您非排他性、不可转让的、不可再许可、不可分许可且可撤销的有限许可，允许您在遵守本协议所述限制的前提下，<strong>仅出于开发、测试、原型设计和演示您的应用程序的目的，并且仅在您的应用程序未用于任何数据处理、业务、商业或生产目的的情况下，且不得用于任何其他目的。</strong>您可以允许您的承包商使用程序，前提是他们代表您行使本协议中授予的许可权利，并且您及您的承包商有责任在此类使用中遵守本协议。您将与您的承包商签订书面协议，严格限制他们使用程序的权利，并以其他方式保护云和恩墨及【MogDB】软件的知识产权，其程度不得低于本协议。您可以在合理必要的范围内复制程序，以行使本协议中授予的许可权利。若云和恩墨发现您允诺使用程序的承包商有不符合要求或使用限制的，云和恩墨可单方中断承包商的使用权限。</p>
          <div className="blank-line small"></div>
          <p>此外，您不得：</p>
          <div className="blank-line mini"></div>
          <ul className="list mini">
            <li><strong>删除</strong>或<strong>修改</strong>任何程序标记或云和恩墨或许方所有权的任何声明；</li>
            <li>以<strong>任何方式</strong>将程序提供给<strong>任何第三方</strong>（本协议中规定的代表您行事的承包商除外）；</li>
            <li>对<strong>云和恩墨</strong>拥有<strong>知识产权</strong>或<strong>获得授权的内容</strong>进行使用、出租、出借、复制、修改、链接、转载、汇编、发表、出版、建立镜像站点、提供第三方培训等使用计划；</li>
            <li>转让本协议或<strong>分发</strong>、<strong>给予</strong>或<strong>转让程序</strong>或<strong>其中的权益给任何第三方</strong>，除非本协议对承包商明确允许（上述内容不应被解释为限制您对单独许可的第三方技术可能享有的权利）；</li>
            <li>以程序及其技术为基础或前提进行<strong>修改</strong>、<strong>升级</strong>、<strong>改造</strong>或<strong>开发其他新程序或新技术</strong>；</li>
            <li>导致或允许对程序进行逆向工程（除非法律要求进行互操作性）、反汇编或反编译、破解或者以其他方式尝试发现程序的源代码；</li>
            <li>在<strong>未经云和恩墨事先同意的情况下</strong>披露任何计划基准测试的结果；</li>
            <li>对程序或者程序运行过程中释放到任何终端内存中的数据、程序运行过程中客户端与服务器端的交互数据，以及 程序运行所必需的系统数据，进行复制、修改、增加、删除、挂接运行或创作任何衍生作品，形式包括但不限于使用插件、外挂或非经合法授权的第三方工具/服务接入程序和相关系统；</li>
            <li><strong>修改</strong>或<strong>伪造</strong>程序运行中的指令、数据，增加、删减、变动程序的功能或运行效果，或者将用于上述用途的程序、方法进行运营或向公众传播，无论上述行为是否为商业目的；</li>
            <li>将<strong>云和恩墨</strong>提供的软件用于非法经营，违反任何法律法规；</li>
            <li>如<strong>云和恩墨</strong>提供给您的程序中包含源代码，除非本协议中明确许可用于其他目的（例如，根据开源许可证许可），否则这些源代码仅根据本协议条款提供参考目的，不得进行解密、修改或其他任何反向工程，或将产品交给他人解密，不得破坏其完整性（包括程序代码、数据等），否则云和恩墨有权<strong>单方终止授权，并保留追究您侵权责任的权利</strong>。</li>
          </ul>
          <p>本协议中未明确授予的所有权利均由云和恩墨保留。如果您希望将程序或您的应用程序用于本协议明确允许以外的任何目的，您必须根据允许此类使用的单独协议从云和恩墨或云和恩墨经销商处获得有效的程序许可。但是，您知晓软件可能不用于生产用途和/或云和恩墨可能无法将程序的某个版本用于生产或其他目的；您通过本协议及计划进行的任何开发或其他工作均由您自行承担风险。</p>
          <div className="blank-line"></div>
          <h2 id="3">4. 知识产权及保密</h2>
          <ul className="list">
            <li>云和恩墨或其许可方保留对程序的所有权和知识产权，其知识产权均受中华人民共和国著作权法、商标法、专利法、反不正当竞争法和相应的国际条约以及其他知识产权法律法规的保护。</li>
            <li>您需对在软件使用过程中获取的云和恩墨的商业秘密（包括但不限于与软件开发有关的信息、客户资料、技术秘密）承担保密义务，直至这些商业秘密通过合法途径进入公众渠道为止。</li>
          </ul>
          <h2 id="4">5. 违约责任</h2>
          <p>如：</p>
          <div className="blank-line mini"></div>
          <ul className="list">
            <li>您违反本协议的规定；</li>
            <li>云和恩墨根据法律要求终止提供服务或软件（例如，向您提供软件属非法行为或者成为非法行为）；</li>
            <li>云和恩墨选择停止全部或部分服务或软件（例如由于法规发生变化，我们继续向您所在地区提供服务变得不现实），云和恩墨有权随时中止或终止授权。如因您的违约行为给云和恩墨造成损失（包括但不限于任何第三方主张的任何索赔、要求或损失、行政处罚、合理的律师费）的，您应在云和恩墨发出书面通知后五个工作日内对云和恩墨进行赔偿。</li>
          </ul>
          <h2 id="5">6. 信息收集</h2>
          <p>程序的安装和/或自动更新过程（如果有）可能会向云和恩墨或其服务提供商传输有关这些过程的有限数量的数据（如运行程序的操作系统类型、版本等），以帮助安装程序更智能地运行。云和恩墨承诺不会存储任何此类数据，更不会将数据与个人身份信息相关联。请参阅【恩墨隐私政策链接】 的云和恩墨隐私政策。</p>
          <div className="blank-line"></div>
          <h2 id="6">7. 免责声明;责任限制</h2>
          <ul className="list">
            <li>云和恩墨及关联公司对程序不作任何类型的担保、保证，不论是明示的、默示的或法令的保证和条件，包括但不限于程序的适销性、适用性、无病毒、无疏忽或无技术瑕疵问题、所有权和无侵权的明示或默示担保和条件，对在任何情况下因使用或不能使用程序所产生的直接、间接、偶然、特殊及后续的损害及风险，云和恩墨及关联公司不承担任何责任。</li>
            <li>在任何情况下，云和恩墨对于与本协议有关或由本协议引起的任何间接的、惩罚性的、特殊的、派生的损失（包括业务损失、收益损失、利润损失、商誉损失、使用数据或其他经济利益的损失），不论是如何产生的，也不论是由对本协议的违约（包括违反保证）还是由侵权造成的，均不负有任何责任，即使事先已被告知此等损失的可能性。另外即使本协议规定的排他性救济没有达到其基本目的，也应排除云和恩墨对上述损失的责任。</li>
            <li>在任何情况下，云和恩墨对本协议所承担的违约赔偿责任总额不超过向您收取的当次服务费用总额（如有）。</li>
          </ul>
          <h2 id="7">8. 无技术支持</h2>
          <p>除非您与云和恩墨另行签订协议明确约定技术支持条款，否则云和恩墨的技术支持部门将不会就本协议下提供的程序向您提供技术支持、电话支持或更新。</p>
          <div className="blank-line"></div>
          <h2 id="8">9. 审计;终止</h2>
          <p>云和恩墨可能会审核您对程序的使用。您可以通过销毁程序的所有副本来终止本协议。如果您未能遵守本协议的任何条款，本协议将自动终止，恕不另行通知，在这种情况下，您应立即销毁程序的所有副本。</p>
          <div className="blank-line"></div>
          <h2 id="9">10.双方的关系</h2>
          <p>云和恩墨是一家独立承包商，我们同意我们之间不存在任何合伙、合资或代理关系。我们每个人都将负责支付我们自己的员工，包括与就业相关的税收和保险。本协议中的任何内容均不得解释为限制任何一方独立开发或分发功能与另一方产品功能相似的软件的权利，只要此类软件中不包含另一方的专有信息。</p>
          <div className="blank-line"></div>
          <h2 id="10">11.完整协议;准据法</h2>
          <p>您同意本协议是程序的完整协议，本协议取代所有先前或同期的协议或陈述，包括任何点击生效、收缩包装或类似许可，或程序先前版本的许可协议。除非以您和云和恩墨授权代表以书面形式签署，否则不得修改本协议，也不得更改或放弃权利和限制。如果本协议的任何条款被认定为无效或不可执行，其余条款将仍然有效。</p>
          <div className="blank-line mini"></div>
          <p>本协议受中华人民共和国大陆地区法律（不包括香港地区、台湾地区、澳门地区的法律法规）管辖，您和云和恩墨同意就因本协议引起或与本协议有关的任何争议应提交云和恩墨所在地有管辖权的人民法院通过诉讼方式解决。</p>
          <div className="blank-line"></div>
          <h2 id="11">12.通知</h2>
          <p>如果您对本许可协议有任何疑问，或者出于任何原因希望联系 云和恩墨，请致电：<span className="link">010-65017590</span>，或者发送邮件：<span className="link">marketing@enmotech.com</span>。</p>
        </div>
        <div className="right-box">
          <div className='ul-title'><Icon type="icon-navigation" size={14} /><span>协议导读</span></div>
          <ul className='ul-box'>
            { titles.map((title, idx) => <li className={ activeAnchor === idx ? 'active' : '' } onClick={() => handleAnchorClick(idx)} key={idx}>{title}</li>) }
          </ul>
        </div>
      </div>
    </Layout>
  )
}
export default Privacy
